import React, {useContext, useEffect, useRef, useState} from 'react';
import DistributionPointSelected from '../../context/MyDistributionPoint';
import {
    Button,
    Col,
    DatePicker,
    Divider,
    Flex,
    Form, message, Modal,
    Pagination,
    Popover,
    Row,
    Space, Spin,
    Table, Tooltip
} from 'antd';
import dayjs from 'dayjs';
import Search from 'antd/lib/input/Search';
import request from '../../services/request';
import {serializeParams} from '../../common/helpers';
import {ComplementsComponent} from '../index';
import moment from 'moment';
import {DeleteOutlined, EditOutlined, EyeOutlined, PlusOutlined } from '@ant-design/icons';
import {dispensaries as dispensaryServices} from '../../services';
import CreateTraspaso from './components/createTraspaso';
import {CfdiAssign} from '../../common/components/CfdiAssign';
import {useSelector} from 'react-redux';
import {EVM_PERMISSION, EXPORT_REPORT_PERMISSION, getPagePermissions} from '../../security/permissions';
import {saveJsonToFile} from '../stockControl/components/jsonViewer';

const { RangePicker } = DatePicker;


export default function Index() {
    const [form] = Form.useForm();
    const { distributionPoint } = useContext(DistributionPointSelected);
    const [filters, setFilters] = useState({
        inputRegisterStartDate: dayjs().subtract(7, 'days').startOf('day').format('YYYY-MM-DD HH:mm:ss'),
        inputRegisterEndDate: dayjs().endOf('day').format('YYYY-MM-DD HH:mm:ss'),
        displayPage: 0,
        inputRegisterFolio: null,
        displayAmount: 10,
        distributionPointId: distributionPoint.id
    });
    const [ selectedAll, setSelectedAll ] = useState(false);
    const [ allKeys, setAllKeys ] = useState([]);
    const [ selectedRowKeys, setSelectedRowKeys] = useState([]);
    const [ isLoading, setIsLoading ] = useState(true);
    const [ isLoadingTable, setIsLoadingTable ] = useState(true);
    const [ showComplement, setShowComplement ] = useState(false);
    const [ currentComplement, setCurrentComplement ] = useState({});
    const [ currentPage, setCurrentPage] = useState(1);
    const [ showModalDelete, setShowModalDelete ] = useState(false);
    const [ traspasos, setTraspasos ] = useState([]);
    const [ currentTraspaso, setCurrentTraspaso ] = useState({});
    const [ showModalTraspaso, setShowModalTraspaso] = useState(false);
    const [ pagination, setPagination ] = useState({});
    const [ showModal, setShowModal ] = useState(false);
    const [ showSpan, setShowSpan ] = useState(false);

    const [distributionPointInfo, setDistributionPointInfo] = useState({});

    const editTraspaso = (traspaso) => {
        setShowModalTraspaso(true);
        setCurrentTraspaso(traspaso);
    }

    const isFetching = useRef(false);
    const hasSelected = selectedRowKeys.length > 0;

    const userData = useSelector(state => state.userData);
    const permissions = userData?.permissions;
    const exportReportsPermissions = getPagePermissions(EXPORT_REPORT_PERMISSION, permissions);

    const fetchDistributionPointInfo = () => {
        if (!distributionPoint.id || isFetching.current) return;
        isFetching.current = true;
        dispensaryServices.get(`distributionPoints/${distributionPoint.id}`)
            .then(response => {
                setDistributionPointInfo(response);
                const updatedFiltes = {
                    ...filters, distributionPointId: response.id
                }
                fetchTraspasos(updatedFiltes);
                setFilters(updatedFiltes)
                setIsLoading(false);
            }).catch(error => {
            console.error('Error fetching distribution point info:', error);
        })
            .finally(() => {
                isFetching.current = false;
            });
    };
    const fetchTraspasos = (params) => {
        setIsLoadingTable(true);
        const queryParams = {
            page: params.displayPage,
            size: params.displayAmount,
            sort: 'folio,DESC',
        };
        request()
            .post(`distributionPoints/${distributionPoint.id}/traspasos${serializeParams(queryParams)}`, params)
            .then((response) => {
                const traspasosDataWithKeys = response.data.content.map((traspaso) => ({
                    ...traspaso,
                    key: traspaso.id,
                }));
                setTraspasos(traspasosDataWithKeys);
                setPagination({ ...pagination, total: response.data.totalElements });
                setCurrentPage(params.displayPage + 1);
            })
            .catch((error) => {
                console.error('Error fetching traspasos:', error);
            })
            .finally(() => {
                setIsLoadingTable(false);
            });
    };

    useEffect(() => {
        fetchDistributionPointInfo();
    }, [distributionPoint]);

    useEffect(() => {
        if (selectedAll) {
            setShowSpan(true);
            const timer = setTimeout(() => {
                setShowSpan(false);
            }, 5000);
            return () => clearTimeout(timer);
        }
    }, [selectedAll]);

    const columns = [
        {
            title: ()=> <Tooltip title="Requerido para expresar el número de registro único y consecutivo de cada recepción, generado por el programa informático.">
                Número de registro
            </Tooltip>,
            dataIndex: 'inputRegisterFolio',

        },
        {
            title: ()=> <Tooltip title="Requerido para expresar el volumen inicial antes de la recepción del producto por operación.">
                Volumen Inicial del Tanque
            </Tooltip>,
            dataIndex: 'initialVolumeTank',
            render: inputRegisterVolume => Intl.NumberFormat('en', { notation: 'standard' }).format(inputRegisterVolume)
        },
        {
            title: ()=> <Tooltip title="Requerido para expresar el volumen final después de la recepción del producto por operación">
                Volumen Final del Tanque
            </Tooltip>,
            dataIndex: 'finalVolumeTank',
            render: finalVolumeTank => Intl.NumberFormat('en', { notation: 'standard' }).format(finalVolumeTank)
        },
        {
            title: ()=> <Tooltip title="Requerido para expresar el volumen final después de la recepción del producto por operación">
                Volumen Recepción
            </Tooltip>,
            dataIndex: 'inputRegisterVolume',
            render: receptionVolume => Intl.NumberFormat('en', { notation: 'standard' }).format(receptionVolume)
        },
        {
            title: ()=> <Tooltip title="Requerido para expresar la temperatura del tanque a la que se realizó la cuantificación y/o totalización de la masa o volumen del hidrocarburo o petrolífero de que se trate, tomando en consideración las condiciones de referencia. La unidad de medida son grados Celsius.">
                Temperatura
            </Tooltip>,
            dataIndex: 'temperature',
            render: temperature => Intl.NumberFormat('en', { notation: 'standard' }).format(temperature)
        },
        {
            title: ()=> <Tooltip title="Requerido para expresar la presión absoluta a la que se realizó la cuantificación y/o totalización de la masa o volumen del hidrocarburo o petrolífero de que se trate, tomando en consideración las condiciones de referencia. La unidad de medida es kPa.">
                Presión Absoluta
            </Tooltip>,
            dataIndex: 'absolutePressure',
            render: absolutePressure => Intl.NumberFormat('en', { notation: 'standard' }).format(absolutePressure)
        },
        {
            title: ()=> <Tooltip title="Requerido para expresar la fecha y hora inicial de cada operación de recepción, deberá expresarse la hora en UTC con la indicación del huso horario yyyy-mm-ddThh:mm:ss+-hh:mm, de acuerdo con la especificación ISO 8601.">
                Fecha de inicio
            </Tooltip>,
            dataIndex: 'inputRegisterReceptionDate',
            render: date => moment(date).format('YYYY/MM/DD HH:mm:ss')
        },{
            title: ()=> <Tooltip title="Requerido para expresar la fecha y hora final de cada operación de recepción, deberá expresarse la hora en UTC con la indicación del huso horario yyyy-mm-ddThh:mm:ss+-hh:mm, de acuerdo con la especificación ISO 8601.">
                Fecha de fin
            </Tooltip>,
            dataIndex: 'inputRegisterEndReceptionDate',
            render: date => moment(date).format('YYYY/MM/DD HH:mm:ss')
        },{
            title: 'Almacén',
            dataIndex: 'inputRegisterWarehouseKey'
        },
        {
            title: 'Edición',
            dataIndex: 'action',
            render: (text, record) => (
                <div>
                        <span>
                            <Button
                                icon={<EditOutlined />}
                                title='Editar Traspaso'
                                onClick={()=> editTraspaso(record)} />
                        </span>
                </div>
            ),
        },
        { title: 'CFDI', dataIndex: 'fiscalId', render: text => text ? text.toUpperCase() : '' },
        {
            title: 'Complemento',
            dataIndex: 'complement',
            render: (_, record) => {
                const isEditable = record.isEditAllowed;
                const hasComplements = record.cfdiComplement || record.storageComplement || record.transportComplement || record.internationalComplement || record.clarifications;

                if (isEditable && !hasComplements) {
                    return (
                        <Popover content="Agregar información para complemento">
                            <Button
                                icon={<PlusOutlined />}
                                onClick={() => changeView({
                                    mainView: false,
                                    showComplement: true,
                                    currentCfdiComplement: record.cfdiComplement,
                                    currentTransportComplement: record.transportComplement,
                                    currentStorageComplement: record.storageComplement,
                                    currentInternationalComplement: record.internationalComplement,
                                    isCurrentEditable: record.isEditAllowed,
                                    inputRegisterUUID: record.id,
                                    clarifications: record.clarifications,
                                })}
                            />
                        </Popover>
                    );
                }

                if (isEditable && hasComplements) {
                    return (
                        <Space>
                            <Popover content="Editar información de complemento">
                                <Button
                                    icon={<EditOutlined />}
                                    onClick={() => changeView({
                                        mainView:false,
                                        showComplement:true,
                                        currentCfdiComplement: record.cfdiComplement,
                                        currentTransportComplement: record.transportComplement,
                                        currentStorageComplement: record.storageComplement,
                                        currentInternationalComplement: record.internationalComplement,
                                        isCurrentEditable: record.isEditAllowed,
                                        inputRegisterUUID: record.id,
                                        clarifications: record.clarifications,
                                    })}
                                />
                            </Popover>
                            <Popover content="Eliminar información de complemento">
                                <Button
                                    icon={<DeleteOutlined />}
                                    danger
                                    onClick={() => handleDelete({
                                        inputRegisterUUID: record.id,
                                        currentCfdiComplement: record.cfdiComplement,
                                        currentTransportComplement: record.transportComplement,
                                        currentStorageComplement: record.storageComplement,
                                        currentInternationalComplement: record.internationalComplement,
                                        cfdiComplementRegisterType: record.movementType,
                                        clarifications: record.clarifications,
                                        delete: true,
                                    })}
                                />
                            </Popover>
                        </Space>
                    );
                }

                if (!isEditable && hasComplements) {
                    return (
                        <Popover content="Ver información de complemento">
                            <Button
                                icon={<EyeOutlined />}
                                onClick={() => changeView({
                                    mainView:false,
                                    showComplement:true,
                                    currentCfdiComplement: record.cfdiComplement,
                                    currentTransportComplement: record.transportComplement,
                                    currentStorageComplement: record.storageComplement,
                                    currentInternationalComplement: record.internationalComplement,
                                    isCurrentEditable: record.isEditAllowed,
                                    inputRegisterUUID: record.id,
                                    clarifications: record.clarifications,
                                })}
                            />
                        </Popover>
                    );
                }

                return <span>Sin complemento asociado</span>;
            }
        }
    ]

    const onSelectChange = (selectedRowKeys, selectedRows, info) => {
        const { type } = info;
        if(type === 'all') {
            setSelectedAll(prev => !prev);
        }

        const selectedKeys = {
            page: `page_${currentPage}`,
            keys: selectedRowKeys,
        };

        setAllKeys(prevState => {
            const pageExists = prevState.find(item => item.page === selectedKeys.page);
            if (pageExists) {
                return prevState.map(item =>
                    item.page === selectedKeys.page ? selectedKeys : item
                );
            } else {
                return [...prevState, selectedKeys];
            }
        });
        setSelectedRowKeys(selectedRowKeys);
    };
    const rowSelection = {
        selectedRowKeys,
        onChange: onSelectChange,
    };

    const openModal = () => setShowModal(true);
    const closeModal = () => setShowModal(false);

    const openModalTraspaso = () => {
        setCurrentTraspaso(undefined)
        setShowModalTraspaso(true);
    }
    const closeModalTraspaso = () => setShowModalTraspaso(false);

    const onAssigned = () => {
        setShowModal(false)
        setAllKeys([]);
        setSelectedRowKeys([]);
        fetchTraspasos(filters);
    }

    const onCreateOrEdit = () => {
        setShowModalTraspaso(false)
        setAllKeys([]);
        setSelectedRowKeys([]);
        fetchTraspasos(filters);
    }

    const onFinish = (values) => {
        console.log('selected values ', values)
    };

    const onPageChange = (page, pageSize) => {
        const updatedFilters = {
            ...filters,
            displayPage: page - 1,
        };
        setFilters(updatedFilters);
        fetchTraspasos(updatedFilters);
        const keys = allKeys.map(item => item.keys);
        setSelectedRowKeys(keys.flat());
        setSelectedAll(false);
        setShowSpan(false);
    };

    const onShowSizeChange = (current, pageSize) => {
        const updatedFilters = {
            ...filters,
            displayPage: 0,
            displayAmount: pageSize,
        };
        setFilters(updatedFilters);
        fetchTraspasos(updatedFilters);
    };

    const onChange = (period, timeString) => {
        const updatedFilters = {
            ...filters,
            inputRegisterStartDate: period[0].format('YYYY-MM-DD HH:mm:ss'),
            inputRegisterEndDate: period[1].format('YYYY-MM-DD HH:mm:ss'),
            distributionPointId: distributionPoint.id,
        };
        setAllKeys([]);
        setFilters(updatedFilters);
        setSelectedRowKeys([]);
        fetchTraspasos(updatedFilters)
    };

    const closeComplementView = () => {
        setShowComplement(false);
        fetchTraspasos(filters);
    }

    const closeModalDelete = () => {
        setShowModalDelete(false);
        fetchTraspasos(filters);
    }

    const changeView = (params) => {
        let complement = {
            currentCfdiComplement: params.currentCfdiComplement,
            isCfdiComplement: params.currentCfdiComplement !== null,

            currentTransportComplement: params.currentTransportComplement,
            isTransportComplement: params.currentTransportComplement !== null,

            currentStorageComplement: params.currentStorageComplement,
            isStorageComplement: params.currentStorageComplement !== null,

            currentInternationalComplement: params.currentInternationalComplement,
            isInternationalComplement: params.currentInternationalComplement !== null,

            isCurrentEditAllowed: params.isCurrentEditable,

            currentCFDIRegisterType: "RECEPCION",
            currentCFDIType: "EGRESO",

            inputRegisterUUID: params.inputRegisterUUID,
            clarifications: params.clarifications,
        };

        setCurrentComplement(complement)
        setShowComplement(params.showComplement);
    }

    const handleOk = () => {
        setIsLoading(true)

        dispensaryServices.put('complements/editComplement', currentComplement)
            .then(response => {
                const jsonResponse = JSON.parse(JSON.stringify(response));
                if (jsonResponse.hasOwnProperty('response')) {
                    message.error(jsonResponse.response.data);
                } else {
                    message.success('Complemento eliminado');
                    setIsLoading(false);
                    setShowModalDelete(false);
                    fetchTraspasos(filters);
                }
            });
    }

    const handleDelete = (params) => {
        setShowModalDelete(true);
        const toDelete = {
            //General info
            receptionUUID: params.inputRegisterUUID,
            transactionType: 'traspaso',

            cfdiComplementRegisterType: params.cfdiComplementRegisterType,

            //CFDI Complement Data
            isCfdiComplement: false,
            currentcfdiComplementUUID: params.currentCfdiComplement !== null ? params.currentCfdiComplement.id : null,

            //Transport Complement Data
            isTransportComplement: false,
            transportComplementUUID: params.currentTransportComplement !== null ? params.currentTransportComplement.id : null,

            //Storage Complement Data
            isStorageComplement: false,
            storageComplementUUID: params.currentStorageComplement !== null ? params.currentStorageComplement.id : null,

            //International Complement Data
            isInternationalComplement: false,
            internationalComplementUUID: params.currentInternationalComplement !== null ? params.currentInternationalComplement.id : null,

            delete: (params.delete !== undefined) ? params.delete : false,

        };
        setCurrentComplement(toDelete);
    }

    const downloadReporte = (type) => {
        const period = form.getFieldValue("period");
        const folio = form.getFieldValue("inputRegisterFolio") && null;
        const params = {
            ...filters,
            inputRegisterStartDate: period[0].format('YYYY-MM-DD HH:mm:ss'),
            inputRegisterEndDate: period[1].format('YYYY-MM-DD HH:mm:ss'),
            distributionPointId: distributionPoint.id,
            inputRegisterFolio: folio,
            reportType: type,
        }
        if (params.reportType === 'csv') {
            dispensaryServices.post(`traspasos/reports/${params.reportType}`, params)
                .then(response => {
                    saveJsonToFile(response, "text/csv", "traspasos.csv");
                }).finally(() => {
                setIsLoading(false);
            });
        }
    }
    return (
        <Spin size="large" tip='Cargando...' spinning={isLoading}>
            <h1>Información del Punto de Distribución</h1>
            { !showComplement &&
                <div>
                    <Form form={form} layout="vertical" onFinish={onFinish}>
                        <Row gutter={16} align="bottom">
                            <Col xs={24} sm={12} md={8} lg={8}>
                                <Form.Item
                                    name="period"
                                    label="Seleccionar periodo"
                                    initialValue={[dayjs().subtract(7, 'days').startOf('day'), dayjs().endOf('day')]}
                                    rules={[{required: true, message: 'Por favor selecciona un periodo.'}]}
                                >
                                    <RangePicker
                                        lang='es'
                                        format="YYYY/MM/DD"
                                        style={{width: '100%'}}
                                        onChange={onChange}
                                    />
                                </Form.Item>
                            </Col>

                            <Col xs={24} sm={12} md={4} lg={4} style={{display: 'flex', alignItems: 'flex-end'}}>
                                <Form.Item style={{width: '100%'}}
                                           label="Búsqueda por número de registro(Folio)"
                                           name='inputRegisterFolio'
                                >
                                    <Search enterButton/>
                                </Form.Item>
                            </Col>
                            <Col xs={24} sm={12} md={4} lg={4} style={{display: 'flex', alignItems: 'flex-end'}}>
                                <Form.Item style={{width: '100%'}}>
                                    <Button type='primary'
                                            onClick={openModalTraspaso}
                                            icon={<PlusOutlined />}
                                            style={{width: '100%'}}>
                                        Registrar un traspaso
                                    </Button>
                                </Form.Item>
                            </Col>
                            {
                                exportReportsPermissions &&
                                <Col xs={24} sm={12} md={4} lg={4} style={{display: 'flex', alignItems: 'flex-end'}}>
                                    <Form.Item style={{width: '100%'}}>
                                        <Button style={{width: '100%'}} onClick={() => downloadReporte("csv")}>
                                            Descargar CSV
                                        </Button>
                                    </Form.Item>
                                </Col>
                            }
                        </Row>
                    </Form>

                    <Divider style={{marginTop: '10px'}}/>
                    <div style={{margin: 16}}>
                        <span style={{color: '#1B95CA'}}>{`Traspasos`}</span>
                    </div>
                    <Flex align="center" gap="middle">
                        <Button type="primary" onClick={openModal} disabled={!hasSelected}>
                            Asignar CFDI
                        </Button>
                        {hasSelected ? `Seleccionada${allKeys.map(item => item.keys).flat().length === 1 ? '' : 's'} ${allKeys.map(item => item.keys).flat().length} ${allKeys.map(item => item.keys).flat().length === 1 ? 'traspaso' : 'traspasos'}` : ''}

                        {showSpan && (
                            <span style={{marginLeft: '10px', color: 'green'}}>
                                ¡Se han seleccionados todos en la pagina {currentPage}!
                            </span>
                        )}
                    </Flex>
                    <Divider/>
                    <Table
                        rowSelection={rowSelection}
                        columns={columns}
                        dataSource={traspasos}
                        pagination={false}
                        loading={isLoadingTable}
                    />
                    <br />
                    <Pagination
                        current={currentPage}
                        total={pagination.total}
                        onChange={onPageChange}
                        onShowSizeChange={onShowSizeChange}
                        pageSizeOptions={['10', '20', '50']}
                        showSizeChanger
                        showTotal={total => `Total ${total} elementos`}
                    />
                    {
                        showModal &&
                        <CfdiAssign
                            open={showModal}
                            onCancel={closeModal}
                            selectedKeys={allKeys.map(item => item.keys).flat()}
                            onAssigned={onAssigned}
                            type='traspaso'
                        />
                    }
                    {
                        showModalDelete &&
                        <Modal
                            open={showModalDelete}
                            title="Atención"
                            onCancel={closeModalDelete}
                            footer={[
                                <Button className="back-button" key="cancel-delete" onClick={closeModalDelete}>Cancelar</Button>,
                                <Button type="danger" key="delete" loading={isLoading} onClick={handleOk}>Confirmar</Button>
                            ]}
                        >
                            <p>¿Está seguro que desea eliminar este complemento? Una vez realizada la acción, la información no podrá ser recuperada.</p>
                        </Modal>
                    }
                    <Divider />
                </div>
            }
            {
                showModalTraspaso &&
                <CreateTraspaso
                    hidden={!showModalTraspaso}
                    closeModal={closeModalTraspaso}
                    distributionPointId={distributionPoint.id}
                    refreshView={onCreateOrEdit}
                    traspaso={currentTraspaso}
                />

            }
            {
                showComplement &&
                <ComplementsComponent
                    transactionType='traspaso'
                    complementType={distributionPointInfo.companyActivityTypeDescription}
                    currentComplementInfo={currentComplement}
                    handleReturnToTable={closeComplementView}
                />
            }
        </Spin>
    );
}