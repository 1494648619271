import React, { useContext, useEffect, useRef, useState, useMemo } from 'react';
import { withRouter } from 'react-router-dom';
import DistributionPointSelected from '../../context/MyDistributionPoint';
import {
	Button,
	Col,
	DatePicker,
	Divider,
	Empty,
	message,
	Modal,
	Row,
	Select,
	Space,
	Switch,
	Table,
	Tabs,
	Tooltip
} from 'antd';
import {
	CheckCircleOutlined, DislikeOutlined,
	DownloadOutlined, EyeOutlined,
	FileAddOutlined, LikeOutlined, MinusOutlined,
	ProfileOutlined, ReadOutlined, WarningOutlined
} from '@ant-design/icons';

import locale from 'antd/es/date-picker/locale/es_ES';
import dayjs from 'dayjs';
import 'dayjs/locale/es-mx';
import { dispensaries as dispensaryServices } from '../../services';
import moment from 'moment/moment';
import {openJsonInModal, saveJsonToFile} from '../stockControl/components/jsonViewer';

dayjs.locale('es-mx');

const DOWNLOAD_ACTION = 'download';
const PREVIEW_ACTION = 'preview';

const ALLOWED_DIFF = 1;
const WARN_DIFF = 2.9;
const DEFAULT_VALUE = 0;

function JsonReportPage(props) {
	const { distributionPoint } = useContext(DistributionPointSelected);
	const isFetching = useRef(false);

	const searchParams = new URLSearchParams(props.location.search);
	const activeTab = searchParams.get('tab') || 'day';
	const [ isLoading, setIsLoading ] = useState(true);
	const [ jsonReports, setJsonReports ] = useState([]);
	const [ selectedRowKeys, setSelectedRowKeys] = useState([]);

	const onChangeTab = (key) => {
		props.history.push(`?tab=${key}`);
		setSelectedReportType(key === 'day' ? 'DIARIO' : 'MENSUAL');
		setFilters((prevFilters) => ({
			...prevFilters,
			reportType: key === 'day' ? 'DIARIO' : 'MENSUAL',
			startDate: key === 'day' ? dayjs().startOf('month') : dayjs().startOf('year'),
			endDate: key === 'day' ? dayjs().endOf('month') : dayjs().endOf('year'),
		}));
	};

	const [filters, setFilters] = useState({
		startDate: activeTab === 'day' ? dayjs().startOf('month') : dayjs().startOf('year'),
		endDate: activeTab === 'day' ? dayjs().endOf('month') : dayjs().endOf('year'),
		reportType: activeTab === 'day' ? 'DIARIO' : 'MENSUAL',
		distributionPointId: distributionPoint?.id || null,
	});

	const [selectedReportType, setSelectedReportType] = useState(activeTab === 'day' ? 'DIARIO' : 'MENSUAL');
	const [filtersOnCreate, setFiltersOnCreate] = useState({
		distributionPointId: distributionPoint?.id || null,
		reportType: selectedReportType,
		startDate: activeTab === 'day' ? dayjs() : dayjs().startOf('month'),
	});

	const updatedFilters = useMemo(
		() => ({
			...filters,
			distributionPointId: distributionPoint?.id || null,
		}),
		[distributionPoint, filters]
	);

	useEffect(() => {
		if (updatedFilters.distributionPointId) {
			const filterOnCreateUpdate = {
				...filtersOnCreate,
				distributionPointId: updatedFilters.distributionPointId,
			}
			setFiltersOnCreate(filterOnCreateUpdate)
			fetchInfo(updatedFilters);
		}
	}, [updatedFilters]);

	const onChangeDate = (date) => {
		const start = date.startOf(activeTab === 'day' ? 'month' : 'year');
		const end = date.endOf(activeTab === 'day' ? 'month' : 'year');

		setFilters((prev) => ({
			...prev,
			startDate: start,
			endDate: end,
		}));
	};
	const onChangeDateOnCreateJson = (date) => {

		const updatedFiltersJsonCreate = {
			...filtersOnCreate,
			reportType: selectedReportType,
			startDate: selectedReportType === 'MENSUAL' ? date.startOf('month') : date,
		}
		setFiltersOnCreate(updatedFiltersJsonCreate);
	}

	const onCreateJson = (params) =>{
		setIsLoading(true);
		const filtros = {
			startDate: params.startDate.format('YYYY-MM-DD'),
			reportType: params.reportType,
			distributionPointId: params.distributionPointId,
		}

		dispensaryServices.post('inventoryControl/jsonReport/generate', filtros)
			.then(response => {
				openJsonInModal(response, undefined, `Json ${filtros.reportType.toLowerCase()} - ${filtros.startDate}`);
			}).catch((error) => {
			message.error(error.response.data.message);
		}).finally(() => {
			setIsLoading(false);
			fetchInfo(filters);
		});
	}
	const disabledDate = (current) => (activeTab === 'day') ? current > dayjs().endOf('month') : current > dayjs().endOf('year');

	const downloadOrView = (urlComplement, reportName, download, action) => {
		setIsLoading(true);
		dispensaryServices.post(`inventoryControl/jsonReport/${urlComplement}`, { reportName, distributionPointId: distributionPoint.id })
			.then(response => {
				(download)
					? saveJsonToFile(response, 'application/json', reportName)
					: openJsonInModal(response, action, `Json Report - ${reportName}`);
			}).finally(() => {
			setIsLoading(false);
		});
	}


	const fetchInfo = (params) => {
		if (!distributionPoint.id || isFetching.current) return;
		isFetching.current = true;

		setIsLoading(true);
		const filtros = {
			startDate: params.startDate.format('YYYY-MM-DD'),
			endDate: params.endDate.format('YYYY-MM-DD'),
			reportType: params.reportType,
			distributionPointId: params.distributionPointId,
		};

		dispensaryServices.post(`inventoryControl/getJsonReportList`, filtros)
			.then((response) => {
				const reportsWithKeys = response.map(jreport => ({
					...jreport,
					key: jreport.idReport,
				}));
				setJsonReports(reportsWithKeys);
			})
			.finally(() => {
				setTimeout(() => setIsLoading(false), 100);
				isFetching.current = false;
			});
	};

	const getDifferenceAttributes = (record) => {
		const {
			salesDifferencePercentage = DEFAULT_VALUE,
			receptionDifferencePercentage = DEFAULT_VALUE,
			differenceInventoryPercentage = DEFAULT_VALUE
		} = record;

		const maxDiff = Math.max(salesDifferencePercentage || DEFAULT_VALUE, receptionDifferencePercentage || DEFAULT_VALUE, differenceInventoryPercentage || DEFAULT_VALUE);

		const options = {
			like: { icon: <LikeOutlined />, color: 'green' },
			line: { icon: <MinusOutlined />, color: 'orange' },
			dislike: { icon: <DislikeOutlined />, color: 'red' }
		};

		if (maxDiff < ALLOWED_DIFF) return options.like;
		if (maxDiff < WARN_DIFF) return options.line;
		return options.dislike;
	};

	const [open, setOpen] = useState(false);
	const [openOnCrete, setOpenOnCrete] = useState(false);
	const [selectedRecord, setSelectedRecord] = useState(null);

	const onCancel = () => {
		setOpen(false);
		setSelectedRecord(null);
	};
	const showInfo = (record) => {
		setSelectedRecord(record);
		setOpen(true);
	};
	const showModalOnCreate = () => setOpenOnCrete(true);
	const cancelOnCreate = () => setOpenOnCrete(false);

	const columns = useMemo(() => {
		const baseColumns = [
			{
				title: 'Fecha Reporte',
				dataIndex: 'creationDate',
				width: '8%',
				align: 'left',
				render: (date) => moment(date).format('YYYY/MM/DD'),
			},
			{
				title: 'Nombre Reporte',
				dataIndex: 'reportName',
				width: activeTab === 'month' ? '40%' : '50%',
				render: (text) => text,
			},
			{
				title: 'Tipo Reporte',
				width: '8%',
				align: 'center',
				dataIndex: 'reportType',
				render: (text) => text,
			},
			{
				title: 'Punto distribución',
				width: activeTab === 'month' ? '12%' : '0%',
				render: () => distributionPoint.name },
			{
				title: 'Acciones',
				width: '10%',
				align: 'center',
				render: (text, r) => (
					<Space>
						<Tooltip title="Descargar Reporte" placement="top">
							<Button icon={<DownloadOutlined />} onClick={() => downloadOrView(DOWNLOAD_ACTION, r.reportName, true)} />
						</Tooltip>
						<Tooltip title="Ver Reporte" placement="top">
							<Button icon={<EyeOutlined />} onClick={() => downloadOrView(PREVIEW_ACTION, r.reportName, false, 'showJson')} />
						</Tooltip>
						<Tooltip title="Inconsistencias Reporte" placement="top">
							<Button
								icon={text === '[]' ? <CheckCircleOutlined /> : <WarningOutlined />}
								style={
									text === '[]'
										? { color: 'green', fontSize: '20px' }
										: { color: 'orange', fontSize: '20px' }
								}
								onClick={() => downloadOrView(PREVIEW_ACTION, r.reportName, false, 'showErrors')}
							/>
						</Tooltip>
					</Space>
				),
			},
		];

		if (activeTab === 'month') {
			baseColumns.push(
				{
					title: 'Diferencias',
					width: '10%',
					align: 'center',
					render: (text, record) => {
						const { icon, color } = getDifferenceAttributes(record);
						return (
							<Tooltip title="Diferencias Reporte">
								<Button
									icon={icon}
									style={{ backgroundColor: color, borderColor: color, color: 'white' }}
									onClick={() => showInfo(record)}
								/>
							</Tooltip>
						);
					},
				},
				{
					title: 'Enviado al SAT',
					dataIndex: 'alreadySent',
					width: '10%',
					align: 'center',
					render: () => <Switch />
				}
			);
		}

		return baseColumns;
	}, [activeTab, distributionPoint]);

	const contentTabSelected = (isMonth) => (
		<div style={{ display: 'flex', flexDirection: 'column', gap: '8px', width: '60%' }}>
			<span style={{ fontWeight: 'bold' }}>Consultar periodo</span>
			<div style={{ display: 'flex', alignItems: 'center', gap: '12px' }}>
				<DatePicker
					locale={locale}
					onChange={onChangeDate}
					picker={isMonth ? 'year' : 'month'}
					format={isMonth ? 'YYYY' : 'MMMM [de] YYYY'}
					placeholder={isMonth ? 'Selecciona un año' : 'Selecciona un mes'}
					disabledDate={disabledDate}
					value={filters.startDate}
					style={{ width: '35%' }}
				/>
				<div style={{ display: 'flex', gap: '12px' }}>
					<span><strong>Desde:</strong> {filters.startDate.format('DD/MM/YYYY')}</span>
					<span><strong>Hasta:</strong> {filters.endDate.format('DD/MM/YYYY')}</span>
				</div>
			</div>
		</div>
	);

	const infoDifference = () => {
		if (!selectedRecord) return null;
		const {
			volumeSalesCfdis = DEFAULT_VALUE,
			volumeReceptionsCfdis = DEFAULT_VALUE,
			volumeSales = DEFAULT_VALUE,
			volumeReceptions = DEFAULT_VALUE,

			lastMeasurement = DEFAULT_VALUE,
			calculatedVolume = DEFAULT_VALUE,
			differenceInventory = DEFAULT_VALUE,
			receptionDifferencePercentage = DEFAULT_VALUE,
			salesDifferencePercentage = DEFAULT_VALUE,
			differenceInventoryPercentage = DEFAULT_VALUE,
			receptionDifferenceVolume = DEFAULT_VALUE,
			salesDifferenceVolume = DEFAULT_VALUE
		} = selectedRecord;
		const dataA = {
			column1: "Volumen final lógico (L)",
			column2: "Volumen final físico (L)",
			column3: "Diferencia (L)",
			column4: "Diferencia (%)",
			values: [calculatedVolume, lastMeasurement, differenceInventory, `${differenceInventoryPercentage}%` || "0%"],
		};

		const dataB = {
			column1: "Entregas (L)",
			column2: "CFDI- Entregas (L)",
			column3: "Diferencia (L)",
			column4: "Diferencia (%)",
			values: [volumeSales, volumeSalesCfdis, salesDifferenceVolume, `${salesDifferencePercentage}%` || "0%"],
		};

		const dataC = {
			column1: "Recepciones (L)",
			column2: "CFDI-Recepciones (L)",
			column3: "Diferencia (L)",
			column4: "Diferencia (%)",
			values: [volumeReceptions, volumeReceptionsCfdis, receptionDifferenceVolume, `${receptionDifferencePercentage}%` || "0%"],
		};

		return (
			<Modal title="Detalle de Diferencias" open={open} onCancel={onCancel} footer={null} centered={true} width={1000}>
				<TableComponent title="A)" data={dataA} />
				<TableComponent title="B)" data={dataB} />
				<TableComponent title="C)" data={dataC} />
			</Modal>
		);
	};

	const TableComponent = ({ title, data }) => {
		return (
			<div style={{ marginBottom: '1rem', border: '1px solid #e0e0e0', borderRadius: '0.5rem', boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)', padding: '1rem' }}>
				<h2 style={{ fontSize: '1.125rem', fontWeight: '600', marginBottom: '0.5rem' }}>{title}</h2>
				<table style={{ width: '100%', borderCollapse: 'collapse', border: '1px solid #e0e0e0' }}>
					<thead>
					<tr style={{ backgroundColor: '#f7fafc' }}>
						<th style={{ border: '1px solid #e0e0e0', padding: '0.5rem' }}>{data.column1}</th>
						<th style={{ border: '1px solid #e0e0e0', padding: '0.5rem' }}>{data.column2}</th>
						<th style={{ border: '1px solid #e0e0e0', padding: '0.5rem' }}>{data.column3}</th>
						<th style={{ border: '1px solid #e0e0e0', padding: '0.5rem' }}>{data.column4}</th>
					</tr>
					</thead>
					<tbody>
					<tr>
						<td style={{ border: '1px solid #e0e0e0', padding: '0.5rem' }}>{data.values[0]}</td>
						<td style={{ border: '1px solid #e0e0e0', padding: '0.5rem' }}>{data.values[1]}</td>
						<td style={{ border: '1px solid #e0e0e0', padding: '0.5rem' }}>{data.values[2]}</td>
						<td style={{ border: '1px solid #e0e0e0', padding: '0.5rem' }}>{data.values[3]}</td>
					</tr>
					</tbody>
				</table>
			</div>
		);
	};


	const isMonth = selectedReportType === 'MENSUAL';
	const [reportDate, setReportDate] = useState(dayjs());

	useEffect(() => {
		if (selectedReportType) {
			setReportDate(selectedReportType === 'MENSUAL' ? dayjs().startOf('month') : dayjs().startOf('day'));
		}
	}, [selectedReportType]);

	const onCreateJsonOK = () => {
		setOpenOnCrete(false);
		setSelectedReportType(activeTab === 'day' ? 'DIARIO' : 'MENSUAL');
		onCreateJson(filtersOnCreate);
	}

	const onCreateJsonModal = () => {
		return (
			<Modal title="Generación de Reporte CV(JSON)"
			   open={openOnCrete}
			   onCancel={cancelOnCreate}
			   footer={[
				   <Button className="back-button" key="cancel" onClick={cancelOnCreate}>Cerrar</Button>,
				   <Button type="primary" key='onCreate' onClick={onCreateJsonOK}>Generar Reporte</Button>
			   ]}
			   onOk={onCreateJsonOK}
			   centered={true}>
				<div style={{ display: 'flex', flexDirection: 'column', gap: '8px', width: '100%' }}>
					<span style={{ fontWeight: 'bold' }}>Tipo reporte</span>
					<div style={{ display: 'flex', alignItems: 'center', gap: '12px', width: '100%' }}>
						<Select
							style={{ width: '100%' }}
							value={selectedReportType}
							onChange={(value) => setSelectedReportType(value)}
							options={
								[
									{ value: 'DIARIO', label: 'DIARIO' },
									{ value: 'MENSUAL', label: 'MENSUAL'}
								]
							}
						/>
					</div>
					{selectedReportType && (
						<>
							<span style={{ fontWeight: 'bold' }}>Fecha de reporte a generar</span>
							<DatePicker
								locale={locale}
								onChange={onChangeDateOnCreateJson}
								picker={isMonth ? 'month' : 'date'}
								format={isMonth ? 'MMMM [de] YYYY' : 'DD [de] MMMM YYYY'}
								placeholder={isMonth ? 'Selecciona un mes' : 'Selecciona un día'}
								disabledDate={disabledDate}
								value={filtersOnCreate.startDate}
								style={{ width: '100%' }}
							/>
						</>
					)}
				</div>
			</Modal>
		)
	}

	const items = [
		{ key: 'day', label: 'Json Diarios', children: contentTabSelected(false), icon: <ProfileOutlined /> },
		{ key: 'month', label: 'Json Mensuales', children: contentTabSelected(true), icon: <ReadOutlined /> },
	];

	const onSelectChange = (selectedRowKeys, selectedRows, info) => {
		setSelectedRowKeys(selectedRowKeys);
	}
	const rowSelection = {
		selectedRowKeys,
		onChange: onSelectChange,
	};

	return (
		<div>
			<span>Por favor selecciona el tipo de reporte a consultar</span>
			<div>
				<Row gutter={[16, 16]} align='top' wrap>
					<Col xs={24} md={16}>
						<Tabs activeKey={activeTab} items={items} onChange={onChangeTab} />
					</Col>
					<Col xs={24} md={8}>
						<Space style={{ width: '100%', display: 'flex', justifyContent: 'flex-end', flexWrap: 'wrap', gap: '8px' }}>
							<Button type="primary" onClick={showModalOnCreate} icon={<FileAddOutlined />} style={{ flex: 1, minWidth: 150 }}>
								Generar Reporte CV(JSON)
							</Button>
							<Button type="default"
									icon={<DownloadOutlined />}
									disabled={selectedRowKeys.length === 0}
									style={{ flex: 1, minWidth: 130 }}>
								Descargar Reportes seleccionados
							</Button>
						</Space>
					</Col>
				</Row>
				<Divider orientation="left" style={{ fontSize: '15px', color: '#606676' }}>Reportes Consultados</Divider>
				<Row gutter={24}>
					<Col span={24}>
						<Table
						   rowSelection={rowSelection}
						   columns={columns}
						   style={ { maxHeight: '450px'}}
						   loading={isLoading}
						   scroll={{ y: 400 }}
						   rowKey={ r => r.idReport}
						   dataSource={jsonReports}
						   locale={{ emptyText: <Empty description="Sin registros" />  }}
						   pagination={false}/>
					</Col>
				</Row>
			</div>
			{ infoDifference() }
			{ onCreateJsonModal() }
		</div>
	);
}

export default withRouter(JsonReportPage);
