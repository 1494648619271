/*
 * @Author: Alejandro Leonor
 * @Date: 2018-03-07 10:34:07
 * @Last Modified by: Alejandro Leonor
 * @Last Modified time: 2018-03-16 11:15:36
 */
import React from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';

import { MainLayout } from '../common/components/layout';
import PrivateRoute from '../security/privateRoute';

// Main Application Routes
import Dashboard from './dashboard';
import Permissions from './permissions';
import Register from './register';
import FirstContainers from './firstContainerRegistry';
import Containers from './containers';
import Users from './users';
import Dispensaries from './dispensaries';
import DispensarySale from './dispensarySale';
import HoseSale from './hoseSale';
import EntryVolumeMeasurer from './entryVolumeMeasurer';
import Receptions from './receptions';
import PGRegistration from './receptions/components/pgRegistration';
import DictumRegistration from './dictums/components/dictumRegistration';
import StockControl from './stockControl';
import Printer from './printer';
import Backups from './backups';
import Alerts from './alerts';
import Logbook from './logbook';
import Dictums from './dictums';
import OperationCertificates from './operationCertificates';
import OperationCertificateRegistration from './operationCertificates/components/operationCertificateRegistration';
import FirstConfigApplication from './firstConfigApplication';
import ConfigurationApplication from './configurationApplication';
import OutputEmailConfig from './configurationOutputEmail';
import HealthInformation from './healthApplication';
import PermissionNumbers from './permissionNumbers';
import CorruptedDataVisualizer from './corruptedDataVisualizer';
import VolumeMeasurerConfig from './volumeMeasurerRegistries';
import FirstWarehouses from './firstWarehouseRegistry';
import Warehouses from './wareHouse';
import EmailSenderConfig from './emailSenderConfigutarion';
import ComplementsComponent from './cfdiComplements/ComplementsComponent';
import DistributionPoints from './distributionPoints';
import Traspasos from './Traspasos';
import JsonReport from './jsonReport/page';
import Subscriptions from './subscriptions';

export {
    Dashboard,
    Permissions,
    Register,
    FirstContainers,
    Containers,
    Users,
    Dispensaries,
    DispensarySale,
    HoseSale,
    EntryVolumeMeasurer,
    Receptions,
    PGRegistration,
    DictumRegistration,
    StockControl,
    Printer,
    Backups,
    Alerts,
    Logbook,
    Dictums,
    OperationCertificates,
    OperationCertificateRegistration,
    FirstConfigApplication,
    ConfigurationApplication,
    OutputEmailConfig,
    HealthInformation,
    PermissionNumbers,
    CorruptedDataVisualizer,
    VolumeMeasurerConfig,
    FirstWarehouses,
    Warehouses,
    EmailSenderConfig,
    ComplementsComponent,
    DistributionPoints,
    Traspasos,
    JsonReport,
    Subscriptions,
};

export default (props) => {
    const { menu } = props;
    return (
        <Router basename="/cv">
            <MainLayout menu={menu}>
                <Switch>
                    <Route path="/receptions/components/pgRegistration/:id/:inputRegister" component={PGRegistration} />
                    <Route path="/dictums/components/dictumRegistration/:id" component={DictumRegistration} />
                    <Route path="/operationCertificates/components/operationCertificateRegistration/:id" component={OperationCertificateRegistration} />
                    {
                        menu.map((item) => {
                            const { secure, ...rest } = item;
                            if (secure) { return (<PrivateRoute exact {...rest} />); }
                            return (<Route exact {...rest} />);
                        })
                    }
                </Switch>
            </MainLayout>
        </Router>);
};
