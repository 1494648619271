import {Button, Col, Divider, Modal, notification, Popconfirm, Row, Table, Tag, Tooltip, Typography} from 'antd';
import { debounce } from 'lodash';
import React, {useCallback, useEffect, useRef, useState} from 'react';
import { dispensaries as dispensaryServices } from '../../services';

import Search from 'antd/lib/input/Search';

export const CfdiAssign = ({ open, onCancel, selectedKeys, onAssigned, type }) => {
    const [data, setData] = useState([]);
    const [selectedId, setSelectedId] = useState(null);
    const [selectedUUID, setSelectedUUID] = useState(null);
    const [ isLoading, setIsLoading ] = useState(false);

    const hasFetchedData = useRef(false);

    const getCfdis = async (searchText) => {
        try {
            setIsLoading(true)
            const params = new URLSearchParams({
                ...(searchText && { query: searchText }),
                ...(type && { type })
            });

            const queryParams = params.toString() ? `?${params.toString()}` : '';

            const response = await dispensaryServices.get(`cfdis/search${queryParams}`);
            setData(response);
            setIsLoading(false);
        } catch (error) {
            console.error(error);
        }
    };

    useEffect(() => {
        if (!hasFetchedData.current) {
            getCfdis();
            hasFetchedData.current = true;
        }
    }, []);

    const rowSelection = {
        type: 'radio',
        onChange: (selectedRowKeys, selectedRows) => {
            setSelectedId(selectedRowKeys[0]);
            setSelectedUUID(selectedRows[0].fiscalId);
        },
    }

    const onChangeSearch = useCallback(
        debounce(async (e) => {
            setIsLoading(true);
            const searchText = e.target.value;
            await getCfdis(searchText);
        }, 500),
        []
    );

    const onConfirm = async () => {
        try {
            if (!selectedUUID || selectedKeys.length === 0) {
                return;
            }
            const response = await dispensaryServices.post(`cfdis/${type}/${selectedId}/assign`, selectedKeys);

            openNotificationWithIcon(response);
            onAssigned();
        } catch (error) {
            console.error('Error asignando CFDIs:', error);
        }
    }

    const openNotificationWithIcon = (descriptionMsg) => {
        notification.success({
            message: 'Asignación exitosa',
            description: descriptionMsg,
        });
    };

    const reactNodeDescription = React.useMemo(() => {
        return (
            <div style={{ padding: '5px', textAlign: 'center' }}>
                <Typography.Paragraph style={{ whiteSpace: 'pre-line' }}>
                    {`¿Está seguro de realizar la asignación del folio fiscal\n`}
                    <Tag color="blue">{selectedUUID ? selectedUUID.toUpperCase() : 'N/A'}</Tag>
                    {`a la${selectedKeys.length === 1 ? '' : `s ${selectedKeys.length}`} ${type}${selectedKeys.length === 1 ? '' : 's'} seleccionada${selectedKeys.length === 1 ? '' : 's'}?`}
                </Typography.Paragraph>
            </div>
        );
    }, [selectedUUID, selectedKeys]);

    return (
        <Modal
            width="80%"
            icon="snippets"
            open={open}
            onCancel={onCancel}
            title={`Asignación de CFDI a ${type}s seleccionadas`}
            footer={[
                <Button key="back" onClick={onCancel}>
                Cancelar
                </Button>,
                <Popconfirm
                    title={`Asignación de CFDI a ${type}s seleccionadas`}
                    description={reactNodeDescription}
                    onConfirm={onConfirm}
                    okText="Sí"
                    cancelText="No"
                >
                    <Button key="submit" type="primary" disabled={!selectedId}>
                        Asignar
                    </Button>
                </Popconfirm>
            ]}
        >
            <div>
                <Row gutter={24}>
                    <Col span={24}>
                        <label>Selecciona un CFDI de la lista a continuación para vincularlo con las { `${type}s` } seleccionadas.</label>
                    </Col>

                    <Col span={24} style={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'center' }}>
                        <Tooltip title="Puedes buscar por {descripcion, ius, cfdi}">
                            <div style={{ width: '50%' }}>
                                <Search
                                    placeholder="Puedes buscar por {descripcion, ius, cfdi}"
                                    style={{ width: '100%' }}
                                    onChange={onChangeSearch}
                                />
                            </div>
                        </Tooltip>
                    </Col>
                    <Divider />

                    <Col span={24}>
                        <Table
                            columns={[
                                ...(type === 'compra' ? [{ title: 'Emisor', dataIndex: 'nameEmisor', align: 'center', width: '15%' }] : []),
                                { title: 'Volumen documentado', dataIndex: 'volume', align: 'center', width: '10%'},
                                { title: 'Precio compra/venta', dataIndex: 'price', align: 'center', width: '10%'},
                                { title: 'CFDI', dataIndex: 'fiscalId', align: 'center', render: text => text ? text.toUpperCase() : '', width: '25%'},
                                { title: 'Fecha Y hora', dataIndex: 'invoiceDate', align: 'center', width: '15%'},
                                { title: 'No. Identificación', dataIndex: 'numberId',},
                                { title: 'Descripción', dataIndex: 'description', width: '25%'},
                            ]}
                            dataSource={data}
                            rowKey='id'
                            rowSelection={rowSelection}
                            pagination={false}
                            loading={isLoading}
                            size="small"
                        />
                    </Col>
                </Row>
            </div>
        </Modal>
    );
};
